import { Menu, message } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { names, room, room_types, Types } from '../utils/interfaces';
import { createFromIconfontCN } from "@ant-design/icons";
import { MenuInfo } from "rc-menu/es/interface";

import { get_string } from "../i18n/strings";
import { Context } from '../utils/store';
import Axios from 'axios';
import { apiUrl } from '../utils/config';
import { handleTokenExpired } from '../utils/utils';

import InfoIcon from '../assets/icons/icons/info.png';
import SportIcon from '../assets/icons/icons/dumbbell.png';
import StudyIcon from '../assets/icons/icons/work-from-home.png';
import RelaxIcon from '../assets/icons/icons/game-console.png';
import KinoIcon from '../assets/icons/icons/cinema-screen.png';

const icons: Partial<{ [key in names]: string }> = {
  info: InfoIcon,
  sport: SportIcon,
  study_room: StudyIcon,
  relax: RelaxIcon,
  kino: KinoIcon
};

const IconFont = createFromIconfontCN({
  scriptUrl: "/assets/font.js",
});

export default function SiderMenu(props: {
  closeMenu: (visible: boolean) => void;
  menuType: 'drawer' | 'sider'
}) {
  const history = useHistory();
  const { state, dispatch } = useContext(Context);
  const [roomTypes, setRoomTypes] = useState<room_types[]>([]);
  const [rooms, setRooms] = useState<room[]>();

  const onClick = (e: MenuInfo) => {
    if (props.menuType === 'drawer') {
      props.closeMenu(false);
    }
  };

  const handleMenuClick = (path: string) => {
    history.push(path);
    if (props.menuType === 'drawer') {
      props.closeMenu(false);
    }
  };

const getRoomLabel = (id: number): string => {
  let label: string | undefined;

  switch (id) {
    case 22:
      label = get_string(state.locale, 'gym') as string;
      break;
    case 23:
      label = get_string(state.locale, 'fitness') as string;
      break;
    case 3:
      label = get_string(state.locale, 'study') as string;
      break;
    case 4:
      label = get_string(state.locale, 'gaming') as string;
      break;
    case 12:
      label = get_string(state.locale, 'kino') as string;
      break;
    default:
      return '';
  }

  return typeof label === 'string' ? label : '';
};

  useEffect(() => {
    Axios.get<room[]>(`${apiUrl}/rooms/`, {
      headers: {
        'Authorization': `JWT ${state.token}`
      }
    })
      .then((res) => {
        const rooms = res.data;
        let room_types: room_types[] = [];
        rooms.map((room) => {
          if (!room_types.includes(room.room_type) && room.reservation_type !== 'not_required') {
            room_types.push(room.room_type);
          }
        });
        setRooms(rooms);
        setRoomTypes(room_types);
      })
      .catch((e) => {
        let isExpired = handleTokenExpired(e.response?.status, () => { dispatch({ type: Types.Logout, payload: null }) });
        if (!isExpired) {
          console.log(e.response);
        }
      });
  }, []);

  return (
    <Menu onClick={onClick} mode={"inline"} theme={'dark'} selectable={true}>
      <Menu.Item
        key="info"
        icon={<img src={InfoIcon} alt="info" style={{ width: "23px", height: "23px", marginRight: "10px", filter: "invert(100%)" }} />}
        onClick={() => handleMenuClick('/')}
      >
        {get_string(state.locale, "info")}
      </Menu.Item>

      {roomTypes && (
        roomTypes.map((type: names, index) => (
          <SubMenu
            key={index + ''}
            style={{ paddingLeft: 0 }}
            icon={icons[type as keyof typeof icons] ? <img src={icons[type as keyof typeof icons]} alt={type} style={{ width: "23px", height: "23px", marginRight: "10px", filter: "invert(100%)" }} /> : null}
            title={get_string(state.locale, type)}
          >
            {rooms && rooms.map((room, roomIndex) => {
              if (room.room_type === type && room.reservation_type !== 'not_required') {
                return (
                  <Menu.Item key={room.id} onClick={() => handleMenuClick(`/room/${room.id}`)}>
                       {`${room.building}/${room.room_number} ${getRoomLabel(room.id)}`}
                  </Menu.Item>
                );
              }
            })}
          </SubMenu>
        ))
      )}
    </Menu>
  );
}

