import React, { useState, useEffect, useContext } from 'react';
import { Form, Input, InputNumber, Button, DatePicker, Card, Typography, Row, Col, Space, message, Modal } from 'antd';
import Axios from 'axios';
import { apiUrl } from "../utils/config";
import { format } from 'date-fns';
import { Context } from "../utils/store";
import { handleTokenExpired } from "../utils/utils";
import moment from 'moment';

const { Title, Paragraph, Text } = Typography;
const { RangePicker } = DatePicker;
const { confirm } = Modal;

const MovieForm = () => {
  const { state, dispatch } = useContext(Context);
  const [movies, setMovies] = useState<any[]>([]);
  const [form] = Form.useForm();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [events, setEvents] = useState<any[]>([]);
  const [subscribers, setSubscribers] = useState<any[]>([]);
  const [editingEvent, setEditingEvent] = useState<number | null>(null);
  const [editingData, setEditingData] = useState<any>({});

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const fetchEvents = async () => {
    try {
      const response = await Axios.get(`${apiUrl}/events/`, {
        headers: { Authorization: `JWT ${state.token}` },
      });
      setEvents(response.data);
    } catch (e) {
      if (!handleTokenExpired(e.response?.status, () => dispatch({ type: "Logout", payload: null }))) {
        console.error(e.response.data);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchEvents();
    };
    fetchData();
  }, [state.token]);

  const fetchSubscribers = async (eventId: number) => {
    try {
      const response = await Axios.get(`${apiUrl}/subscription/find/?event_id=${eventId}&start=1727928000&end=1727985600`, {
        headers: { Authorization: `JWT ${state.token}` },
      });
      setSubscribers(response.data);
    } catch (e) {
      console.error(e);
    }
  };

  const handleEdit = (eventId: number) => {
    const event = events.find(e => e.EventID === eventId);
    if (event) {
      setEditingData({ ...event });
      setEditingEvent(eventId);
    }
  };

  const handleInputChange = (field: string, value: any) => {
    setEditingData({ ...editingData, [field]: value });
  };

  const handleSaveEdit = async () => {
    try {
      await Axios.put(`${apiUrl}/event/update/?event_id=${editingData.EventID}&Name=${editingData.Name}&Description=${editingData.Description}&Start=${editingData.Start}&End=${editingData.End}&NumberOfSeats=${editingData.NumberOfSeats}&Image=${encodeURIComponent(editingData.Image)}`, null, {
        headers: { Authorization: `JWT ${state.token}` },
      });
      message.success('Event updated successfully');
      setEditingEvent(null);
      fetchEvents();
    } catch (error) {
      message.error('Failed to update event');
      console.error('Error:', error);
    }
  };

  const handleCancelEdit = () => {
    setEditingEvent(null);
    setEditingData({});
  };

  const handleDeleteEvent = (eventId: number) => {
    confirm({
      title: 'Are you sure you want to delete this event?',
      onOk: async () => {
        try {
          await Axios.delete(`${apiUrl}/event/delete/?event_id=${eventId}`, {
            headers: { Authorization: `JWT ${state.token}` },
          });
          message.success('Event deleted successfully');
          fetchEvents();
        } catch (error) {
          message.error('Failed to delete event');
          console.error('Error:', error);
        }
      },
    });
  };

  const handleFormSubmit = (values: any) => {
    const newMovie = {
      title: values.title,
      description: values.description,
      start: values.showTime[0].format('YYYY-MM-DD HH:mm'),
      end: values.showTime[1].format('YYYY-MM-DD HH:mm'),
      seats: values.seats,
      poster: values.poster,
    };
    setMovies([...movies, newMovie]);
    form.resetFields();
  };

  const handleClear = () => form.resetFields();

  const handleDeleteMovie = (index: number) => {
    const updatedMovies = movies.filter((_, i) => i !== index);
    setMovies(updatedMovies);
  };

  const handleSave = async () => {
    for (let movie of movies) {
      try {
        await Axios.post(
          `${apiUrl}/event/add/?RoomID=12&Name=${movie.title}&Description=${movie.description}&Start=${movie.start}&End=${movie.end}&NumberOfSeats=${movie.seats}&Image=${encodeURIComponent(movie.poster)}`,
          null,
          {
            headers: { Authorization: `JWT ${state.token}` },
          }
        );
        message.success(`Successfully saved movie: ${movie.title}`);
      } catch (error) {
        message.error(`Failed to save movie: ${movie.title}`);
        console.error('Error:', error);
      }
    }
    setMovies([]);
  };

  return (
    <div style={styles.container}>
      <Form form={form} layout="vertical" onFinish={handleFormSubmit} style={styles.form}>
        <Form.Item label="Title" name="title" rules={[{ required: true, message: 'Please input the movie title!' }]}>
          <Input placeholder="Enter movie title" />
        </Form.Item>
        <Form.Item label="Description" name="description" rules={[{ required: true, message: 'Please input the movie description!' }]}>
          <Input.TextArea placeholder="Enter movie description" />
        </Form.Item>
        <Form.Item label="Show Time" name="showTime" rules={[{ required: true, message: 'Please select the show time!' }]}>
          <RangePicker showTime={{ format: 'HH:mm' }} format="YYYY-MM-DD HH:mm" />
        </Form.Item>
        <Form.Item label="Seats" name="seats" rules={[{ required: true, message: 'Please input the number of seats!' }]}>
          <InputNumber min={1} placeholder="Number of seats" style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item label="Poster URL" name="poster" rules={[{ required: true, message: 'Please input the poster URL!' }]}>
          <Input placeholder="Enter poster URL" />
        </Form.Item>
        <div style={styles.buttonContainer}>
          <Button onClick={handleClear} type="default">Clear</Button>
          <Button htmlType="submit" type="primary" style={styles.addButton}>+ Add</Button>
        </div>
      </Form>

      <div style={styles.previewContainer}>
        <Title level={3}>Preview</Title>
        <Row gutter={[16, 16]}>
          {movies.map((movie, index) => (
            <Col key={index} xs={24} sm={24} md={24} lg={24} xl={24}>
              {isMobile ? (
                <Card hoverable style={styles.mobileCard}>
                  <div style={styles.mobileImageWrapper}>
                    <img alt="Movie poster" src={movie.poster || "https://via.placeholder.com/150x200"} style={styles.mobileImage} />
                  </div>
                  <div style={styles.mobileTextContent}>
                    <Title level={4} style={styles.mobileCardTitle}>{movie.title}</Title>
                    <Paragraph>{movie.description}</Paragraph>
                    <Paragraph><Text strong>Total Seats:</Text> {movie.seats}</Paragraph>
                    <Paragraph><Text strong>Start:</Text> {movie.start}</Paragraph>
                    <Paragraph><Text strong>End:</Text> {movie.end}</Paragraph>
                    <Button type="primary" onClick={() => handleDeleteMovie(index)}>Delete</Button>
                  </div>
                </Card>
              ) : (
                <Card hoverable style={styles.card}>
                  <div style={styles.cardContent}>
                    <div style={styles.imageWrapper}>
                      <img alt="Movie poster" src={movie.poster || "https://via.placeholder.com/150x200"} style={styles.image} />
                    </div>
                    <div style={styles.textContent}>
                      <div style={styles.titleRow}>
                        <Title level={4} style={styles.cardTitle}>{movie.title}</Title>
                        <Text style={styles.seatInfo}><Text strong>Total Seats:</Text> {movie.seats}</Text>
                      </div>
                      <Paragraph><Text strong>Start:</Text> {movie.start}</Paragraph>
                      <Paragraph><Text strong>End:</Text> {movie.end}</Paragraph>
                      <Paragraph>{movie.description}</Paragraph>
                      <Button type="primary" onClick={() => handleDeleteMovie(index)}>Delete</Button>
                    </div>
                  </div>
                </Card>
              )}
            </Col>
          ))}
        </Row>
      </div>

      <div style={styles.saveButtonContainer}>
        <Button type="primary" onClick={handleSave}>Save</Button>
      </div>

      <div style={styles.adminPanel}>
        <Title level={3}>Manage Events</Title>
        <Row gutter={[16, 16]}>
          {events.map((event) => (
            <Col key={event.EventID} xs={24} sm={24} md={24} lg={24} xl={24}>
              <Card hoverable style={styles.card}>
                {editingEvent === event.EventID ? (
                  <Form layout="vertical">
                    <Form.Item label="Title">
                      <Input value={editingData.Name} onChange={(e) => handleInputChange("Name", e.target.value)} />
                    </Form.Item>
                    <Form.Item label="Description">
                      <Input.TextArea value={editingData.Description} onChange={(e) => handleInputChange("Description", e.target.value)} />
                    </Form.Item>
                    <Form.Item label="Show Time">
                      <RangePicker
                        value={[moment(editingData.Start), moment(editingData.End)]}
                        onChange={(dates) => {
                          if (dates && dates[0] && dates[1]) {
                            handleInputChange("Start", format(dates[0].toDate(), 'YYYY-MM-DD HH:mm'));
                            handleInputChange("End", format(dates[1].toDate(), 'YYYY-MM-DD HH:mm'));
                          }
                        }}
                      />
                    </Form.Item>
                    <Form.Item label="Seats">
                      <InputNumber min={1} value={editingData.NumberOfSeats} onChange={(value) => handleInputChange("NumberOfSeats", value)} />
                    </Form.Item>
                    <Form.Item label="Poster URL">
                      <Input value={editingData.Image} onChange={(e) => handleInputChange("Image", e.target.value)} />
                    </Form.Item>
                    <div style={styles.buttonContainer}>
                      <Button type="primary" onClick={handleSaveEdit}>Save</Button>
                      <Button onClick={handleCancelEdit}>Cancel</Button>
                    </div>
                  </Form>
                ) : (
                  <div>
                    <Title level={4}>{event.Name}</Title>
                    <Paragraph>{event.Description}</Paragraph>
                    <Paragraph><Text strong>Total Seats:</Text> {event.NumberOfSeats}</Paragraph>
                    <Paragraph><Text strong>Start:</Text> {format(new Date(event.Start), 'PPpp')}</Paragraph>
                    <Paragraph><Text strong>End:</Text> {format(new Date(event.End), 'PPpp')}</Paragraph>
                    <img src={event.Image} alt="Poster" style={styles.image} />
                    <div style={styles.buttonContainer}>
                      <Button type="primary" onClick={() => handleEdit(event.EventID)}>Update</Button>
                      <Button danger onClick={() => handleDeleteEvent(event.EventID)}>Delete</Button>
                    </div>
                    <Button type="dashed" onClick={() => fetchSubscribers(event.EventID)}>Show Subscribers</Button>
                    {subscribers.length > 0 && (
                      <div style={styles.subscribersList}>
                        <Title level={5}>Subscribers:</Title>
                        {subscribers.map((subscription: any) => (
                          <Paragraph key={subscription.subscription_id}>
                            {subscription.user.firstname} {subscription.user.lastname} ({subscription.user.id})
                          </Paragraph>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

const styles = {
  container: { padding: '20px' },
  form: { maxWidth: '600px', marginBottom: '40px' },
  buttonContainer: { display: 'flex', justifyContent: 'space-between', marginBottom: '5px', marginTop: '5px' },
  addButton: { marginLeft: '10px' },
  previewContainer: { marginTop: '40px' },
  card: { width: '100%', marginBottom: '20px' },
  cardContent: { display: 'flex', flexDirection: 'row' as 'row', justifyContent: 'space-between' as 'space-between', alignItems: 'center' as 'center', width: '100%' },
  imageWrapper: { flexShrink: 0, marginRight: '20px' },
  image: { width: '250px', height: '350px', objectFit: 'cover' as 'cover' },
  textContent: { display: 'flex', flexDirection: 'column' as 'column', flexGrow: 1, justifyContent: 'space-between' as 'space-between', alignItems: 'flex-start' as 'flex-start', width: '100%' },
  titleRow: { display: 'flex', justifyContent: 'space-between', width: '100%' },
  cardTitle: { fontSize: '24px', marginBottom: '0' },
  seatInfo: { fontSize: '16px' },
  mobileCard: { width: '100%', textAlign: 'center' as 'center' },
  mobileImageWrapper: { marginBottom: '20px' },
  mobileImage: { width: '150px', height: '200px', objectFit: 'cover' as 'cover' },
  mobileTextContent: { display: 'flex', flexDirection: 'column' as 'column', alignItems: 'center' as 'center' },
  mobileCardTitle: { fontSize: '20px' },
  saveButtonContainer: { marginTop: '40px', display: 'flex', justifyContent: 'flex-end' },
  adminPanel: { marginBottom: '40px' },
  subscribersList: { marginTop: '20px' },
};

export default MovieForm;

